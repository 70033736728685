@import url(https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;600;700&display=swap);
/* Works - pass "my-custom-class" in cssClass to increase specificity */
.my-custom-class .modal-wrapper {
  background: #222 !important;
}

.code-modal {
  --height: 250px !important;
  --width: 85vw !important;
  --border-radius: 10px !important;
  --background: #ffefef;
}

.header-modal {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #cecece;
  font-weight: bold;
}

.config-items ion-datetime {
  font-size: 0px;
  padding: 0 !important;
}

.config-items ion-icon {
  margin-right: 10px !important;
}

.config-items ion-text {
  color: #b38585;
}

.title-modal {
  color: #b38585 !important;
}

.modal-save-button {
  margin: 10px !important;
  --background: #ffbebe !important;
}
.modal-close-button {
  margin: 10px !important;
  --background: #b38585 !important;
}

@media screen and (max-width: 344px) {
  .pack-question {
    font-size: 12.8px;
  }
  .code-modal {
    --height: 230px !important;
  }
}

/* Works - pass "my-custom-class" in cssClass to increase specificity */
.my-custom-class .modal-wrapper {
  background: #222!important;
}

.pill-modal {
  --height: 390px!important;
  --width: 95%!important;
  --border-radius: 10px!important;
  --background: #ffefef;
  overflow-y: hidden!important;
}

.no-overflow {
  --overflow: hidden!important;
}

.cartela-inicio {
  color: #b38585;
  font-size: 10px;
}

.forget-pill {
  background-color: #ff0000!important;
  border: 1px solid #b30000!important;
  color: #fafafa!important;
}

.forget-white-pill {
  border: 1px solid #b30000!important;
  color: #b30000!important;
  font-weight: bold;
}

.header-modal {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #cecece;
  font-weight: bold;
}

.title-modal {
  color: #b38585!important;
}

.modal-save-button {
  margin: 10px!important;
  --background: #ffbebe!important;
}

.modal-close-button {
  margin: 10px!important;
  --background: #b38585!important;
}

.pill {
  height: 20px;
  width: 20px;
  background: #ffbebe;
  border-radius: 100%;
  margin: 0 auto!important;
  box-shadow: 1px 2px 3px #b38585;
  border: 1px solid #b38585;
  font-size: 8px;
  color: #b38585;
  display: flex;
  align-items: center;
  justify-content: center;
}

.white-pill {
  background: white;
  border: 1px solid #cecece;
}

.pill-already-taken {
  opacity: 0.5;
}

.pills-main {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}

.pills-container {
  display: flex;
}

.auto-column {
  padding-top: 10px;
  width: 14.3%;
  display: block;
  margin: 0 auto;
  line-height: 13px;
  height: 45px;
}

.legendas {
  color: #b38585;
}

.auto-column span, p {
  font-size: 10px;
  color: #b38585;
}

.week-days {
  background: #ffbebe;
  border-radius: 5px;
}
.week-days p {
  font-weight: bold;
  text-transform: capitalize;
}

.week-days .auto-column {
  border-right: 2px solid #ffefef;
  height: 35px;
}
.week-days .auto-column:last-child {
  border-right: none
}

.pill-pack {
  padding-bottom: 10px;
  border: 1px solid #b38585;
  border-radius: 8px;
  background-color: #ffbebe26!important;
  box-shadow: 1px 1px 5px #b38585;
}


@-webkit-keyframes RedFade {  
  0% { 
    background-color: #ff0000;
    border: 1px solid #b30000;
    color: #fafafa;
   }
  50% { 
    background-color: #ffbebe;
    border: 1px solid #b38585;
    color:  #b38585;
   }
  100% {
    background-color: #ff0000;
    border: 1px solid #b30000;
    color:  #fafafa;
  }
}


@keyframes RedFade {  
  0% { 
    background-color: #ff0000;
    border: 1px solid #b30000;
    color: #fafafa;
   }
  50% { 
    background-color: #ffbebe;
    border: 1px solid #b38585;
    color:  #b38585;
   }
  100% {
    background-color: #ff0000;
    border: 1px solid #b30000;
    color:  #fafafa;
  }
}

@-webkit-keyframes BlueFade {  
  0% { 
    background-color: #bfcfff;
    border: 1px solid #677fc7;
    color: #fafafa;
   }
  50% { 
    background-color: #ffbebe;
    border: 1px solid #b38585;
    color:  #b38585;
   }
  100% {
    background-color: #bfcfff;
    border: 1px solid #677fc7;
    color:  #fafafa;
  }
}

@keyframes BlueFade {  
  0% { 
    background-color: #bfcfff;
    border: 1px solid #677fc7;
    color: #fafafa;
   }
  50% { 
    background-color: #ffbebe;
    border: 1px solid #b38585;
    color:  #b38585;
   }
  100% {
    background-color: #bfcfff;
    border: 1px solid #677fc7;
    color:  #fafafa;
  }
}

@-webkit-keyframes BlueWhite {  
  0% { 
    background-color: #bfcfff;
    border: 1px solid #677fc7;
    color: #fafafa;
   }
  50% { 
    background-color: white;
    border: 1px solid #b38585;
    color:  #b38585;
   }
  100% {
    background-color: #bfcfff;
    border: 1px solid #677fc7;
    color:  #fafafa;
  }
}

@keyframes BlueWhite {  
  0% { 
    background-color: #bfcfff;
    border: 1px solid #677fc7;
    color: #fafafa;
   }
  50% { 
    background-color: white;
    border: 1px solid #b38585;
    color:  #b38585;
   }
  100% {
    background-color: #bfcfff;
    border: 1px solid #677fc7;
    color:  #fafafa;
  }
}

@-webkit-keyframes YellowFade {  
  0% { 
    background-color: #FFFF99;
    border: 1px solid #c7c74d;
    color: black;

   }
  50% { 
    background-color: #ffbebe;
    border: 1px solid #b38585;
    color:  #b38585;
   }
  100% {
    background-color: #FFFF99;
    border: 1px solid #c7c74d;
    color:  black;

  }
}

@keyframes YellowFade {  
  0% { 
    background-color: #FFFF99;
    border: 1px solid #c7c74d;
    color: black;

   }
  50% { 
    background-color: #ffbebe;
    border: 1px solid #b38585;
    color:  #b38585;
   }
  100% {
    background-color: #FFFF99;
    border: 1px solid #c7c74d;
    color:  black;

  }
}

@-webkit-keyframes YellowWhiteFade {  
  0% { 
    background-color: #FFFF99;
    border: 1px solid #c7c74d;
    color: black;

   }
  50% { 
    background-color: white;
    border: 1px solid #b38585;
    color:  #b38585;
   }
  100% {
    background-color: #FFFF99;
    border: 1px solid #c7c74d;
    color:  black;

  }
}

@keyframes YellowWhiteFade {  
  0% { 
    background-color: #FFFF99;
    border: 1px solid #c7c74d;
    color: black;

   }
  50% { 
    background-color: white;
    border: 1px solid #b38585;
    color:  #b38585;
   }
  100% {
    background-color: #FFFF99;
    border: 1px solid #c7c74d;
    color:  black;

  }
}

.pulse {
  background-color: #bfcfff;
  -webkit-animation-name: BlueFade;
  animation-name: BlueFade;
  -webkit-animation-iteration-count: infinite;  
  animation-iteration-count: infinite;  
  -webkit-animation-duration: 2s; 
  animation-duration: 2s; 
}   

.white-pulse {
  background-color: white;
  -webkit-animation-name: BlueWhite;
  animation-name: BlueWhite;
  -webkit-animation-iteration-count: infinite;  
  animation-iteration-count: infinite;  
  -webkit-animation-duration: 2s; 
  animation-duration: 2s; 
}   

.pill-selected {
  background-color: #FFFF99;
  -webkit-animation-name: YellowFade;
  animation-name: YellowFade;
  -webkit-animation-iteration-count: infinite;  
  animation-iteration-count: infinite;  
  -webkit-animation-duration: 2s; 
  animation-duration: 2s; 
}  

.white-pill-selected {
  background-color: white;
  -webkit-animation-name: YellowWhiteFade;
  animation-name: YellowWhiteFade;
  -webkit-animation-iteration-count: infinite;  
  animation-iteration-count: infinite;  
  -webkit-animation-duration: 2s; 
  animation-duration: 2s; 
}   

/* --------------- legendas */

.legendas-p {
  width: 65px;
  display: flex;
  justify-content: space-between;
}

.legendas-button {
  width: 15px;
  height: 15px;
  background-color: #ffbebe;
  border: 1px solid #b38585;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.legendas-label {
  font-size: 12px;
}

.legendas-sub {
  font-size: 10px;
}

.legenda-sub-item {
  background-color: #ffbebe26;
  border: 1px solid #b38585!important;
  border-radius: 5px;
  padding-right: 5px;
  margin-right: 5px;  
}

.legenda-take-today {
  width: 10px;
  height: 10px;
  background-color: #bfcfff;
  border-radius: 50%;
}

.legenda-active {
  width: 10px;
  height: 10px;
  background-color: #FFFF99;
  border-radius: 50%;
}

.legenda-forgot {
  width: 10px;
  height: 10px;
  background-color: #ff0000;
  border-radius: 50%;

}
:root {
  --ion-font-family: "Mulish", sans-serif !important;
}

* {
  font-family: "Mulish", sans-serif !important;
}

p {
  margin: 0 !important;
}

ion-content {
  --background: #ffefef !important;
}

ion-item {
  --background: #ffefef !important;
  --border-color: none !important;
  --border-style: none !important;
  --highlight-color-focused: none !important;
}

.ion-title-custom {
  color: #b38585 !important;
}
.font-weight-bold {
  font-weight: bold;
}

.header-icon-color {
  fill: #b38585 !important;
  color: #b38585 !important;
}

.header-icon-color-light {
  color: #ffbebe !important;
  color: #ffbebe !important;
}

.settings-icon {
  height: 30px !important;
  width: 30px !important;
}

.custom-input {
  border: 1px solid #cecece;
  padding-left: 10px !important;
  border-radius: 5px;
}

.ion-item-custom {
  box-shadow: 3px 1px 8px #b3858563 !important;
  margin-bottom: 15px;
}

.result-message {
  color: #b38585;
  font-size: 12px;
}

ion-list-header {
  padding: 0px !important;
}

.ion-list-title {
  font-size: 15px;
}

.w-100 {
  width: 100vw;
}

.text-center {
  text-align: center;
}

.custom-bottom-toast {
  /* --max-width: 70vw!important; */
  --width: 75vw !important;
  --end: "right-to-left" !important;
  --height: 55px !important;
}

.no-item-message {
  line-height: 25px;
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mt-3 {
  margin-top: 10%;
}

.custom-input-label {
  padding-left: 10px !important;
  color: #cecece !important;
}

.report-items .card {
  border: 2px solid #b38585 !important;
  padding: 5px;
  border-radius: 8px;
}

.report-items .card.inner-card {
  border: 1px solid #b38585 !important;
}

.header-bg {
  height: 130px;
  width: 110vw;
  box-shadow: 5px 10px 10px #ffbebe !important;
  background: linear-gradient(90deg, #ffbebe 0%, #ffdfdf 100%);
  -webkit-transform: rotate(-3deg);
          transform: rotate(-3deg);
  border-radius: 10px 10px 10px 50px;
  left: -18px;
  top: -50px;
  bottom: 50px;
  position: absolute;
}

.header-bg-content-delivered {
  background: linear-gradient(90deg, #75d681 0%, #ffdfdf 100%);
}
.header-bg-content-not-delivered {
  background: linear-gradient(90deg, rgb(204 44 18) 0%, rgb(47 77 160) 100%);
}
.header-bg-content-shipping {
  background: linear-gradient(90deg, #e3e62e 0%, #ffdfdf 100%);
}

.header-md::after {
  background-image: none !important;
}

ion-radio {
  border-color: #b38585 !important;
  --color-checked: #b38585 !important;
  --color: #ffdfdf !important;
  color: #ffdfdf !important;
}

.border-yaz {
  border: 2px solid #b38585;
  border-radius: 8px;
}

.fab-button-package {
  --background: #ffbebe !important;
  --background-activated: #ffbebe !important;
  --background-focused: #ffbebe !important;
  --background-hover: #ffbebe !important;
}

.segment-yaz {
  background-color: #ffdfdf;
  color: #b38585;
  font-weight: bold;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffdfdf;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ffbebe;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ffbebe;
}

/* TEXT-COLORS */

.text-color-yaz-light {
  color: #ffbebe;
}
.text-color-yaz-dark {
  color: #b38585;
}
ion-toolbar {
  --background: transparent;
  --border-color: none;
  --border-style: none;
  --ion-color-base: transparent !important;
  max-width: calc(100vw - 30px);
}

.title-page-header {
  margin-top: 10px;
}

.option-list-item ion-text {
  color: #ffbebe !important;
  font-weight: bold;
}

ion-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
}

.custom-input-header {
  color: #fafafa !important;
  font-weight: bold;
}

.list-content {
  padding-left: 5px;
}

.plus-button {
  padding: 6px 10px 5px 10px;
  color: #fff !important;
  background: rgb(56, 128, 255);
  box-shadow: 1px 1px 1px #cecece;
  border-radius: 100%;
}

.ion-note {
  margin: 10px;
  border-radius: 5px;
  --background: #ffbebe !important;
  border-left: 6px solid #b38585;
  margin-bottom: 30px;
}

.ion-note-alert {
  margin: 10px;
  border-radius: 5px;
  --background: #fff;
  border-left: 6px solid #b38585;
  margin-bottom: 10px !important;
  box-shadow: 1px 3px 4px #b38585;
}

.ion-note-alert p {
  color: #b38585;
  font-size: 13px;
  font-weight: bold;
}

.ion-note p {
  color: #b38585;
  font-size: 15px;
  font-weight: bold;
}

.ion-note p:last-child {
  color: #b38585;
  font-size: 12px;
  text-align: end;
  margin-top: 20px !important;
}

@media screen and (max-width: 344px) {
  .ion-note p {
    font-size: 12px;
    line-height: 13px;
  }

  .option-list-item {
    height: 40px;
  }
}

.header-bg {
  margin-bottom: 35px;
}

.report-items .key {
  color: #b38585;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
}

.report-items .value {
  text-align: center;
  color: #ffbebe;
  font-weight: bold;
  font-size: 13px;
}

.cicle-recommendations {
  border-left: 5px solid #b3858563;
  border-right: 5px solid #b3858563;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 1px 1px 5px #ffbebe;
  font-size: 13px;
}

.or-line {
  width: 45%;
  height: 1px;
  background-color: #ffbebe;
}

.or-text {
  color: #b38585;
  font-weight: bold;
  font-style: italic;
}

.ion-note-smaller {
  margin-top: 0px !important;
  font-size: 13px !important;
  text-align: center;
  line-height: 16px !important;
  font-weight: 500 !important;
}

.question-yaz {
  font-size: 13px;
}

@media screen and (max-width: 344px) {
  .report-items .key {
    font-size: 11px;
  }

  .report-items .value {
    font-size: 11px;
  }
}

