.header-bg {
  margin-bottom: 35px;
}

.report-items .key {
  color: #b38585;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
}

.report-items .value {
  text-align: center;
  color: #ffbebe;
  font-weight: bold;
  font-size: 13px;
}

.cicle-recommendations {
  border-left: 5px solid #b3858563;
  border-right: 5px solid #b3858563;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 1px 1px 5px #ffbebe;
  font-size: 13px;
}

.or-line {
  width: 45%;
  height: 1px;
  background-color: #ffbebe;
}

.or-text {
  color: #b38585;
  font-weight: bold;
  font-style: italic;
}

.ion-note-smaller {
  margin-top: 0px !important;
  font-size: 13px !important;
  text-align: center;
  line-height: 16px !important;
  font-weight: 500 !important;
}

.question-yaz {
  font-size: 13px;
}

@media screen and (max-width: 344px) {
  .report-items .key {
    font-size: 11px;
  }

  .report-items .value {
    font-size: 11px;
  }
}
