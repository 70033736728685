@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;600;700&display=swap");

:root {
  --ion-font-family: "Mulish", sans-serif !important;
}

* {
  font-family: "Mulish", sans-serif !important;
}

p {
  margin: 0 !important;
}

ion-content {
  --background: #ffefef !important;
}

ion-item {
  --background: #ffefef !important;
  --border-color: none !important;
  --border-style: none !important;
  --highlight-color-focused: none !important;
}

.ion-title-custom {
  color: #b38585 !important;
}
.font-weight-bold {
  font-weight: bold;
}

.header-icon-color {
  fill: #b38585 !important;
  color: #b38585 !important;
}

.header-icon-color-light {
  color: #ffbebe !important;
  color: #ffbebe !important;
}

.settings-icon {
  height: 30px !important;
  width: 30px !important;
}

.custom-input {
  border: 1px solid #cecece;
  padding-left: 10px !important;
  border-radius: 5px;
}

.ion-item-custom {
  box-shadow: 3px 1px 8px #b3858563 !important;
  margin-bottom: 15px;
}

.result-message {
  color: #b38585;
  font-size: 12px;
}

ion-list-header {
  padding: 0px !important;
}

.ion-list-title {
  font-size: 15px;
}

.w-100 {
  width: 100vw;
}

.text-center {
  text-align: center;
}

.custom-bottom-toast {
  /* --max-width: 70vw!important; */
  --width: 75vw !important;
  --end: "right-to-left" !important;
  --height: 55px !important;
}

.no-item-message {
  line-height: 25px;
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mt-3 {
  margin-top: 10%;
}

.custom-input-label {
  padding-left: 10px !important;
  color: #cecece !important;
}

.report-items .card {
  border: 2px solid #b38585 !important;
  padding: 5px;
  border-radius: 8px;
}

.report-items .card.inner-card {
  border: 1px solid #b38585 !important;
}

.header-bg {
  height: 130px;
  width: 110vw;
  box-shadow: 5px 10px 10px #ffbebe !important;
  background: linear-gradient(90deg, #ffbebe 0%, #ffdfdf 100%);
  transform: rotate(-3deg);
  border-radius: 10px 10px 10px 50px;
  left: -18px;
  top: -50px;
  bottom: 50px;
  position: absolute;
}

.header-bg-content-delivered {
  background: linear-gradient(90deg, #75d681 0%, #ffdfdf 100%);
}
.header-bg-content-not-delivered {
  background: linear-gradient(90deg, rgb(204 44 18) 0%, rgb(47 77 160) 100%);
}
.header-bg-content-shipping {
  background: linear-gradient(90deg, #e3e62e 0%, #ffdfdf 100%);
}

.header-md::after {
  background-image: none !important;
}

ion-radio {
  border-color: #b38585 !important;
  --color-checked: #b38585 !important;
  --color: #ffdfdf !important;
  color: #ffdfdf !important;
}

.border-yaz {
  border: 2px solid #b38585;
  border-radius: 8px;
}

.fab-button-package {
  --background: #ffbebe !important;
  --background-activated: #ffbebe !important;
  --background-focused: #ffbebe !important;
  --background-hover: #ffbebe !important;
}

.segment-yaz {
  background-color: #ffdfdf;
  color: #b38585;
  font-weight: bold;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffdfdf;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ffbebe;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ffbebe;
}

/* TEXT-COLORS */

.text-color-yaz-light {
  color: #ffbebe;
}
.text-color-yaz-dark {
  color: #b38585;
}