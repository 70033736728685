/* Works - pass "my-custom-class" in cssClass to increase specificity */
.my-custom-class .modal-wrapper {
  background: #222 !important;
}

.code-modal {
  --height: 250px !important;
  --width: 85vw !important;
  --border-radius: 10px !important;
  --background: #ffefef;
}

.header-modal {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #cecece;
  font-weight: bold;
}

.config-items ion-datetime {
  font-size: 0px;
  padding: 0 !important;
}

.config-items ion-icon {
  margin-right: 10px !important;
}

.config-items ion-text {
  color: #b38585;
}

.title-modal {
  color: #b38585 !important;
}

.modal-save-button {
  margin: 10px !important;
  --background: #ffbebe !important;
}
.modal-close-button {
  margin: 10px !important;
  --background: #b38585 !important;
}

@media screen and (max-width: 344px) {
  .pack-question {
    font-size: 12.8px;
  }
  .code-modal {
    --height: 230px !important;
  }
}
