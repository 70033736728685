ion-toolbar {
  --background: transparent;
  --border-color: none;
  --border-style: none;
  --ion-color-base: transparent !important;
  max-width: calc(100vw - 30px);
}

.title-page-header {
  margin-top: 10px;
}

.option-list-item ion-text {
  color: #ffbebe !important;
  font-weight: bold;
}

ion-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
}

.custom-input-header {
  color: #fafafa !important;
  font-weight: bold;
}

.list-content {
  padding-left: 5px;
}

.plus-button {
  padding: 6px 10px 5px 10px;
  color: #fff !important;
  background: rgb(56, 128, 255);
  box-shadow: 1px 1px 1px #cecece;
  border-radius: 100%;
}

.ion-note {
  margin: 10px;
  border-radius: 5px;
  --background: #ffbebe !important;
  border-left: 6px solid #b38585;
  margin-bottom: 30px;
}

.ion-note-alert {
  margin: 10px;
  border-radius: 5px;
  --background: #fff;
  border-left: 6px solid #b38585;
  margin-bottom: 10px !important;
  box-shadow: 1px 3px 4px #b38585;
}

.ion-note-alert p {
  color: #b38585;
  font-size: 13px;
  font-weight: bold;
}

.ion-note p {
  color: #b38585;
  font-size: 15px;
  font-weight: bold;
}

.ion-note p:last-child {
  color: #b38585;
  font-size: 12px;
  text-align: end;
  margin-top: 20px !important;
}

@media screen and (max-width: 344px) {
  .ion-note p {
    font-size: 12px;
    line-height: 13px;
  }

  .option-list-item {
    height: 40px;
  }
}
