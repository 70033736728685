/* Works - pass "my-custom-class" in cssClass to increase specificity */
.my-custom-class .modal-wrapper {
  background: #222!important;
}

.pill-modal {
  --height: 390px!important;
  --width: 95%!important;
  --border-radius: 10px!important;
  --background: #ffefef;
  overflow-y: hidden!important;
}

.no-overflow {
  --overflow: hidden!important;
}

.cartela-inicio {
  color: #b38585;
  font-size: 10px;
}

.forget-pill {
  background-color: #ff0000!important;
  border: 1px solid #b30000!important;
  color: #fafafa!important;
}

.forget-white-pill {
  border: 1px solid #b30000!important;
  color: #b30000!important;
  font-weight: bold;
}

.header-modal {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #cecece;
  font-weight: bold;
}

.title-modal {
  color: #b38585!important;
}

.modal-save-button {
  margin: 10px!important;
  --background: #ffbebe!important;
}

.modal-close-button {
  margin: 10px!important;
  --background: #b38585!important;
}

.pill {
  height: 20px;
  width: 20px;
  background: #ffbebe;
  border-radius: 100%;
  margin: 0 auto!important;
  box-shadow: 1px 2px 3px #b38585;
  border: 1px solid #b38585;
  font-size: 8px;
  color: #b38585;
  display: flex;
  align-items: center;
  justify-content: center;
}

.white-pill {
  background: white;
  border: 1px solid #cecece;
}

.pill-already-taken {
  opacity: 0.5;
}

.pills-main {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}

.pills-container {
  display: flex;
}

.auto-column {
  padding-top: 10px;
  width: 14.3%;
  display: block;
  margin: 0 auto;
  line-height: 13px;
  height: 45px;
}

.legendas {
  color: #b38585;
}

.auto-column span, p {
  font-size: 10px;
  color: #b38585;
}

.week-days {
  background: #ffbebe;
  border-radius: 5px;
}
.week-days p {
  font-weight: bold;
  text-transform: capitalize;
}

.week-days .auto-column {
  border-right: 2px solid #ffefef;
  height: 35px;
}
.week-days .auto-column:last-child {
  border-right: none
}

.pill-pack {
  padding-bottom: 10px;
  border: 1px solid #b38585;
  border-radius: 8px;
  background-color: #ffbebe26!important;
  box-shadow: 1px 1px 5px #b38585;
}


@keyframes RedFade {  
  0% { 
    background-color: #ff0000;
    border: 1px solid #b30000;
    color: #fafafa;
   }
  50% { 
    background-color: #ffbebe;
    border: 1px solid #b38585;
    color:  #b38585;
   }
  100% {
    background-color: #ff0000;
    border: 1px solid #b30000;
    color:  #fafafa;
  }
}

@keyframes BlueFade {  
  0% { 
    background-color: #bfcfff;
    border: 1px solid #677fc7;
    color: #fafafa;
   }
  50% { 
    background-color: #ffbebe;
    border: 1px solid #b38585;
    color:  #b38585;
   }
  100% {
    background-color: #bfcfff;
    border: 1px solid #677fc7;
    color:  #fafafa;
  }
}

@keyframes BlueWhite {  
  0% { 
    background-color: #bfcfff;
    border: 1px solid #677fc7;
    color: #fafafa;
   }
  50% { 
    background-color: white;
    border: 1px solid #b38585;
    color:  #b38585;
   }
  100% {
    background-color: #bfcfff;
    border: 1px solid #677fc7;
    color:  #fafafa;
  }
}

@keyframes YellowFade {  
  0% { 
    background-color: #FFFF99;
    border: 1px solid #c7c74d;
    color: black;

   }
  50% { 
    background-color: #ffbebe;
    border: 1px solid #b38585;
    color:  #b38585;
   }
  100% {
    background-color: #FFFF99;
    border: 1px solid #c7c74d;
    color:  black;

  }
}

@keyframes YellowWhiteFade {  
  0% { 
    background-color: #FFFF99;
    border: 1px solid #c7c74d;
    color: black;

   }
  50% { 
    background-color: white;
    border: 1px solid #b38585;
    color:  #b38585;
   }
  100% {
    background-color: #FFFF99;
    border: 1px solid #c7c74d;
    color:  black;

  }
}

.pulse {
  background-color: #bfcfff;
  -webkit-animation-name: BlueFade;
  animation-name: BlueFade;
  -webkit-animation-iteration-count: infinite;  
  animation-iteration-count: infinite;  
  -webkit-animation-duration: 2s; 
  animation-duration: 2s; 
}   

.white-pulse {
  background-color: white;
  -webkit-animation-name: BlueWhite;
  animation-name: BlueWhite;
  -webkit-animation-iteration-count: infinite;  
  animation-iteration-count: infinite;  
  -webkit-animation-duration: 2s; 
  animation-duration: 2s; 
}   

.pill-selected {
  background-color: #FFFF99;
  -webkit-animation-name: YellowFade;
  animation-name: YellowFade;
  -webkit-animation-iteration-count: infinite;  
  animation-iteration-count: infinite;  
  -webkit-animation-duration: 2s; 
  animation-duration: 2s; 
}  

.white-pill-selected {
  background-color: white;
  -webkit-animation-name: YellowWhiteFade;
  animation-name: YellowWhiteFade;
  -webkit-animation-iteration-count: infinite;  
  animation-iteration-count: infinite;  
  -webkit-animation-duration: 2s; 
  animation-duration: 2s; 
}   

/* --------------- legendas */

.legendas-p {
  width: 65px;
  display: flex;
  justify-content: space-between;
}

.legendas-button {
  width: 15px;
  height: 15px;
  background-color: #ffbebe;
  border: 1px solid #b38585;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.legendas-label {
  font-size: 12px;
}

.legendas-sub {
  font-size: 10px;
}

.legenda-sub-item {
  background-color: #ffbebe26;
  border: 1px solid #b38585!important;
  border-radius: 5px;
  padding-right: 5px;
  margin-right: 5px;  
}

.legenda-take-today {
  width: 10px;
  height: 10px;
  background-color: #bfcfff;
  border-radius: 50%;
}

.legenda-active {
  width: 10px;
  height: 10px;
  background-color: #FFFF99;
  border-radius: 50%;
}

.legenda-forgot {
  width: 10px;
  height: 10px;
  background-color: #ff0000;
  border-radius: 50%;

}